import * as React from 'react';
import { FlagIcon } from '@heroicons/react/outline';

import Map from '../map';
import { Headline } from '../layout/typography';

const Trip = () => (
  <section className="relative pb-32">
    <div className="max-w-4xl mx-auto px-4 lg:max-w-5xl">
      <div className="relative pb-24 text-center">
        <div className="flex justify-center">
          <div className="flex flex-none w-12 h-12 items-center justify-center rounded-md bg-timothy-blue text-white">
            <FlagIcon className="h-8 w-8" aria-hidden="true" />
          </div>
        </div>
        <div className="mt-3">
          <Headline className="mb-6 text-6xl tracking-tight font-extrabold">
            &apos;Round
            <br />
            the World
          </Headline>
        </div>
        <p className="mt-5 text-lg text-gray-700">
          From October 2016 to April 2017 I was on a trip through 10 countries,
          covering around <span className="font-bold">70,000 km</span> in total.
          We started in Singapore and ended in New York City. To keep family and
          friends updated I coded a{' '}
          <span className="font-bold">quick and dirty iOS app</span> which saved
          my phone&apos;s coordinates under certain conditions and sent them to
          my server whenever it was connected. I then took those coordinates to
          display our trip on a live map. The whole trip was planned for about a
          year in advance and it was the most educating thing I ever did. My
          favorite place? <span className="font-bold">Tokyo!</span>
        </p>
      </div>
    </div>
    <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
      <div className="rounded-2xl overflow-hidden shadow-xl h-screen-4/5 lg:h-96">
        <Map />
      </div>
    </div>
  </section>
);

export default Trip;
