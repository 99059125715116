import {
  CursorClickIcon,
  LocationMarkerIcon,
  MailIcon,
  PhoneIcon,
  UserIcon,
} from '@heroicons/react/outline';
import * as React from 'react';

import { StaticImage } from 'gatsby-plugin-image';
import { Headline } from '../layout/typography';

const Info = () => (
  <section id="hero" className="lg:h-screen-4/5 w-full flex flex-wrap">
    <div className="w-full lg:w-1/2 h-full pt-32 px-2 lg:px-8 flex items-end lg:items-center lg:justify-end">
      <div className="w-full lg:w-auto text-center lg:text-left z-10">
        <Headline className="text-6xl sm:text-4xl tracking-tight font-extrabold lg:text-5xl xl:text-6xl">
          Timothy
          <br />
          Krechel
        </Headline>
        <h2 className="text-gray-800 text-2xl sm:text-4xl tracking-tight font-extrabold lg:text-3xl xl:text-4xl">
          Tech Enthusiast, <br />
          Music Afficionado, <br />
          Great At Googling
        </h2>
        <ul className="py-8 space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8">
          <li className="flex items-center justify-center lg:justify-start col-span-2">
            <div className="flex flex-none w-8 h-8 items-center justify-center rounded-md bg-timothy-blue text-white">
              <LocationMarkerIcon className="h-4 w-4" aria-hidden="true" />
            </div>
            <p className="ml-2 text-base leading-6 text-gray-900">
              Leostraße 18, 53844 Troisdorf, Germany
            </p>
          </li>
          <li className="flex items-center justify-center lg:justify-start">
            <div className="flex flex-none w-8 h-8 items-center justify-center rounded-md bg-timothy-blue text-white">
              <UserIcon className="h-4 w-4" aria-hidden="true" />
            </div>
            <p className="ml-2 text-base leading-6 text-gray-900">
              Born July 9, 1992
            </p>
          </li>
          <li className="flex items-center justify-center lg:justify-start">
            <div className="flex flex-none w-8 h-8 items-center justify-center rounded-md bg-timothy-blue text-white">
              <PhoneIcon className="h-4 w-4" aria-hidden="true" />
            </div>
            <p className="ml-2 text-base leading-6 text-gray-900">
              +49 152 28515543
            </p>
          </li>
          <li className="flex items-center justify-center lg:justify-start">
            <div className="flex flex-none w-8 h-8 items-center justify-center rounded-md bg-timothy-blue text-white">
              <MailIcon className="h-4 w-4" aria-hidden="true" />
            </div>
            <p className="ml-2 text-base leading-6 text-gray-900">
              hello@timothy.de
            </p>
          </li>
          <li className="flex items-center justify-center lg:justify-start">
            <div className="flex flex-none w-8 h-8 items-center justify-center rounded-md bg-timothy-blue text-white">
              <CursorClickIcon className="h-4 w-4" aria-hidden="true" />
            </div>
            <p className="ml-2 text-base leading-6 text-gray-900">
              www.timothy.de
            </p>
          </li>
        </ul>
      </div>
    </div>
    <div className="relative w-full lg:w-1/2 h-96 pt-12 lg:h-full lg:pt-32">
      <div className="absolute -top-1 -right-1 h-full z-0 hidden lg:block">
        <svg className="h-full w-auto" viewBox="0 0 600 600" version="1.1">
          <g>
            <path
              d="M1.814,0.716L599.365,0.716L599.365,599.116L1.814,0.716Z"
              fill="#7e42e1"
            />
          </g>
        </svg>
      </div>
      <div className="w-full h-full flex justify-center items-center">
        <StaticImage
          className="w-96 h-96"
          src="../../images/hero.png"
          alt="A picture of Timothy"
          placeholder="blurred"
        />
      </div>
    </div>
  </section>
);

export default Info;
