import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import mapboxgl from 'mapbox-gl';
import ReactMapGL, { NavigationControl } from 'react-map-gl';
import styled from 'styled-components';
import 'mapbox-gl/dist/mapbox-gl.css';

import { hasWindow } from '../utils/dom';

mapboxgl.workerClass =
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

export default function Map() {
  const { site } = useStaticQuery(
    graphql`
      query TokenQuery {
        site {
          siteMetadata {
            mapboxToken
          }
        }
      }
    `
  );

  const token = site.siteMetadata.mapboxToken;

  const [viewport, setViewport] = useState({
    longitude: 103.8198,
    latitude: 1.352,
    zoom: 1.1,
  });

  if (!token) {
    console.error(
      'ERROR: Mapbox token is required in gatsby-config.js siteMetadata'
    );
  }

  // if there is no window, we cannot render this component
  if (!hasWindow) {
    return null;
  }

  return (
    <ReactMapGL
      mapboxApiAccessToken={token}
      {...viewport}
      width="100%"
      height="100%"
      mapStyle="mapbox://styles/andyetitcompiles/cky9wliba1du614qs61pkr8ds"
      onViewportChange={setViewport}
    >
      <StyledControl showCompass={false} />
    </ReactMapGL>
  );
}

const StyledControl = styled(NavigationControl)`
  right: 10px;
  top: 10px;
`;
