import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { Headline } from '../layout/typography';

import social from '../../utils/social';
import { formatGCMSDateShort } from '../../utils/date';

const Experiences = () => {
  const {
    allGraphCmsExperience: { nodes: experiences },
    allGraphCmsDegree: { nodes: degrees },
  } = useStaticQuery(graphql`
    query ExperiencesAndDegrees {
      allGraphCmsExperience(sort: { fields: endDate, order: DESC }) {
        nodes {
          id
          organization
          title
          startDate
          endDate
          description
        }
      }
      allGraphCmsDegree(sort: { fields: endDate, order: DESC }) {
        nodes {
          id
          organization
          title
          startDate
          endDate
          description
          grade
        }
      }
    }
  `);

  return (
    <section className="bg-white py-16 px-4 sm:px-6 lg:pt-24 lg:pb-32 lg:px-8">
      <div className="relative max-w-lg mx-auto lg:max-w-7xl">
        <div className="grid grid-flow-row-dense grid-cols-5 w-full gap-x-16">
          <div className="col-span-5 lg:col-span-3">
            <Headline className="text-5xl tracking-tight font-extrabold lg:text-3xl xl:text-4xl">
              Experience
            </Headline>
            <ul className="pt-10 grid gap-16 lg:grid-cols-1 lg:gap-x-5 lg:gap-y-12">
              {experiences.map((experience, index) => (
                <Experience key={index} experience={experience} />
              ))}
            </ul>
          </div>
          <div className="col-span-5 lg:col-span-2">
            <Headline className="mt-6 lg:mt-0 text-5xl tracking-tight font-extrabold lg:text-3xl xl:text-4xl">
              Education
            </Headline>
            <ul className="pt-10 grid gap-16 lg:grid-cols-1 lg:gap-x-5 lg:gap-y-12">
              {degrees.map((degree, index) => (
                <Degree key={index} degree={degree} />
              ))}
            </ul>
            <Headline className="mt-6 text-5xl tracking-tight font-extrabold lg:text-3xl xl:text-4xl">
              Interests
            </Headline>
            <ul className="flex flex-wrap items-center">
              {interests.map((interest, index) => (
                <li
                  key={index}
                  className={`uppercase text-gray-900 mr-2 font-bold text-${interest.size}xl`}
                >
                  {interest.text}
                </li>
              ))}
            </ul>
            <Headline className="mt-6 text-5xl tracking-tight font-extrabold lg:text-3xl xl:text-4xl">
              Social
            </Headline>
            <ul className="flex flex-col">
              {social.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  target="_blank"
                  rel="noreferrer"
                  className="text-gray-900 hover:text-blue-500 flex items-center py-2 gap-2"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon
                    className="h-8 w-8 text-gray-500"
                    aria-hidden="true"
                  />
                  <p className="text-2xl">{item.name}</p>
                </a>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experiences;

const Experience = ({ experience }) => {
  const [truncated, setTruncated] = React.useState(true);

  return (
    <li>
      <div className="flex justify-between w-full text-sm text-gray-500">
        <p>{experience.organization}</p>
        <div className="flex justify-end flex-grow">
          <time dateTime={experience.startDate}>
            {formatGCMSDateShort(experience.startDate)}
          </time>
          <p className="mx-1">-</p>
          <time dateTime={experience.endDate}>
            {experience.endDate
              ? formatGCMSDateShort(experience.endDate)
              : 'today'}
          </time>
          <p></p>
        </div>
      </div>
      <div className="mt-2 block">
        <p className="text-xl font-semibold text-gray-900 transition transition-height">
          {experience.title}
        </p>
        {truncated ? (
          <p className="mt-3 text-base text-gray-500 transition-all">
            {experience.description.length < 137
              ? experience.description
              : experience.description.replace(/^(.{137}[^\s]*).*/, '$1') +
                ' ...'}
          </p>
        ) : (
          <p className="mt-3 text-base text-gray-500 transition-all">
            {experience.description}
          </p>
        )}
      </div>
      {experience.description.length >= 137 && (
        <div className="mt-3">
          <button
            className="text-base font-semibold text-timothy-blue hover:text-blue-500"
            onClick={() => setTruncated(!truncated)}
          >
            {truncated ? 'More' : 'Less'}
          </button>
        </div>
      )}
    </li>
  );
};

const Degree = ({ degree }) => {
  return (
    <li>
      <div className="flex justify-between w-full text-sm text-gray-500">
        <p>{degree.organization}</p>
        <div className="flex justify-end flex-grow">
          <time dateTime={degree.startDate}>
            {formatGCMSDateShort(degree.startDate)}
          </time>
          <p className="mx-1">-</p>
          <time dateTime={degree.endDate}>
            {degree.endDate ? formatGCMSDateShort(degree.endDate) : 'today'}
          </time>
          <p></p>
        </div>
      </div>
      <div className="mt-2 block">
        <p className="text-xl font-semibold text-gray-900">{degree.title}</p>
        <p className="mt-3 text-base text-gray-500">{degree.description}</p>
        {degree.grade && (
          <p className="mt-3 text-sm text-gray-500">Grade: {degree.grade}</p>
        )}
      </div>
    </li>
  );
};

const interests = [
  { text: 'Guitar', size: 3 },
  { text: 'Reading', size: 6 },
  { text: 'Video Games', size: 1 },
  { text: 'Piano', size: 2 },
  { text: 'Wakeboard', size: 1 },
  { text: 'Politics', size: 1 },
  { text: 'Travel', size: 3 },
  { text: 'Code', size: 5 },
  { text: 'Memes', size: 1 },
  { text: 'Band', size: 3 },
];
