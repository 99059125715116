import * as React from 'react';
import portrait from '../../images/fredi.jpg';

const Testimonial = () => (
  <section className="py-12 overflow-hidden md:py-20 lg:py-24">
    <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="relative">
        <blockquote className="mt-10">
          <div className="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-900">
            <p>
              &ldquo;Timothy creates a{' '}
              <span className="text-timothy-blue font-bold">
                ridiculous amount of output
              </span>{' '}
              and has mastered the art of knowing{' '}
              <span className="text-timothy-blue font-bold">
                how much quality is enough
              </span>
              . He is a product person by trade. He can develop product
              strategies and can bring them to life. He can{' '}
              <span className="text-timothy-blue font-bold">
                code, design, manage, craft hypotheses and test them
              </span>
              . <br />
              Can't recommend him enough.&rdquo;
            </p>
          </div>
          <footer className="mt-8">
            <div className="md:flex md:items-center md:justify-center">
              <div className="md:shrink-0">
                <img
                  className="mx-auto h-10 w-10 rounded-full"
                  src={portrait}
                  alt="Portrait of Frederik"
                />
              </div>
              <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                <div className="text-base font-medium text-gray-900">
                  Frederik Vosberg
                </div>

                <svg
                  className="hidden md:block mx-1 h-5 w-5 text-timothy-blue"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M11 0h3L9 20H6l5-20z" />
                </svg>

                <div className="text-base font-medium text-gray-500">
                  Head of Innovation @ tarent solutions GmbH
                </div>
              </div>
            </div>
          </footer>
        </blockquote>
      </div>
    </div>
  </section>
);

export default Testimonial;
