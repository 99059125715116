import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Headline } from '../layout/typography';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function getRatingClass(rating) {
  switch (rating) {
    case 1:
      return 'w-1/10';
    case 2:
      return 'w-2/10';
    case 3:
      return 'w-3/10';
    case 4:
      return 'w-4/10';
    case 5:
      return 'w-5/10';
    case 6:
      return 'w-6/10';
    case 7:
      return 'w-7/10';
    case 8:
      return 'w-8/10';
    case 9:
      return 'w-9/10';
    case 10:
      return 'w-10/10';
    default:
      return 'w-0/10';
  }
}

const Skills = () => {
  const {
    allGraphCmsSkillset: { nodes: skillsets },
  } = useStaticQuery(graphql`
    query Skillsets {
      allGraphCmsSkillset(sort: { fields: skills___id, order: ASC }) {
        nodes {
          skills {
            id
            rating
            text
          }
          title
          description
          id
        }
      }
    }
  `);

  return (
    <section
      id="skills"
      className="relative py-16 bg-white overflow-hidden bg-timothy-dark-blue"
    >
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg text-left max-w-5xl mx-auto">
          <Headline className="mb-6 text-7xl tracking-tight font-extrabold md:text-9xl">
            Tools
            <br />
            &amp; Skills
          </Headline>
        </div>
        <div className="max-w-5xl mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <div className="col-span-1 flex flex-col gap-y-4">
              {skillsets
                .filter(
                  (_, index) =>
                    index % 2 === 0 && index !== skillsets.length - 1
                )
                .map((skillset) => {
                  return <Skillset key={skillset.id} skillset={skillset} />;
                })}
            </div>
            <div className="col-span-1 lg:-mt-16 flex flex-col gap-y-4">
              {skillsets
                .filter(
                  (_, index) =>
                    index % 2 !== 0 || index === skillsets.length - 1
                )
                .map((skillset) => {
                  return <Skillset key={skillset.id} skillset={skillset} />;
                })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Skills;

const Skillset = ({ skillset }) => (
  <div>
    <div className="bg-timothy-card-bg text-white px-6 py-8">
      <h2 className="text-3xl font-bold py-1">{skillset.title}</h2>
      <p className="text-lg text-timothy-card-text pb-6">
        {skillset.description}
      </p>
      <ul>
        {skillset.skills.map(({ id, rating, text }) => (
          <li key={id} className="flex items-center py-1">
            <div className="w-1/2">{text}</div>
            <div className="w-1/2 h-1 bg-black bg-opacity-25 rounded-full overflow-hidden">
              <div
                className={classNames(
                  getRatingClass(rating),
                  'h-full bg-gradient-to-r from-timothy-pink to-timothy-orange'
                )}
              />
            </div>
          </li>
        ))}
      </ul>
    </div>
  </div>
);
