import * as React from 'react';

import Credibility from '../components/sections/credibility';
import Experiences from '../components/sections/experiences';
import Info from '../components/sections/info';
import Skills from '../components/sections/skills';
import Testimonial from '../components/sections/testimonial';
import Trip from '../components/sections/trip';
import Layout from '../components/layout/layout';

const VitaPage = () => {
  return (
    <Layout title="Vita">
      <Info />
      <Experiences />
      <Skills />
      <Testimonial />
      <Credibility />
      <Trip />
    </Layout>
  );
};

export default VitaPage;
