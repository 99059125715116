import * as React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import {
  BadgeCheckIcon,
  SparklesIcon,
  AcademicCapIcon,
  FireIcon,
} from '@heroicons/react/outline';

import { Headline } from '../layout/typography';

import formatGCMSDate, { formatGCMSDateShort } from '../../utils/date';

const Credibility = () => {
  const {
    allGraphCmsPublication: { nodes: publications },
    allGraphCmsAchievement: { nodes: achievements },
  } = useStaticQuery(graphql`
    query Credibility {
      allGraphCmsPublication(
        limit: 8
        sort: { fields: date, order: DESC }
        filter: { stage: { eq: PUBLISHED } }
      ) {
        nodes {
          date
          id
          title
          url
          tags {
            ... on GraphCMS_Tag {
              id
              name
              textColor {
                hex
              }
              backgroundColor {
                hex
              }
            }
          }
        }
      }
      allGraphCmsAchievement {
        nodes {
          text
          date
          achievementType
        }
      }
    }
  `);

  return (
    <section className="bg-white py-16 px-4 sm:px-6 lg:pt-24 lg:pb-32 lg:px-8">
      <div className="relative max-w-lg mx-auto lg:max-w-7xl">
        <div className="grid grid-flow-row-dense grid-cols-6 w-full gap-x-16">
          <div className="col-span-6 lg:col-span-3">
            <Headline className="text-5xl tracking-tight font-extrabold lg:text-3xl xl:text-4xl">
              Publications
            </Headline>
            <ul className="pt-10 grid gap-16 lg:grid-cols-1 lg:gap-x-5 lg:gap-y-6">
              {publications.map((publication, index) => (
                <Publication key={index} publication={publication} />
              ))}
            </ul>
            <div className="py-12 sm:mt-8 flex justify-start">
              <div className="sm:mt-0 mt-3 flex-1 lg:flex-none">
                <Link
                  to="/publications"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-timothy-blue hover:bg-blue-700 md:py-4 md:text-lg md:px-10"
                >
                  View All Publications
                </Link>
              </div>
            </div>
          </div>
          <div className="col-span-6 lg:col-span-3">
            <Headline className="mt-6 lg:mt-0 text-5xl tracking-tight font-extrabold lg:text-3xl xl:text-4xl">
              Achievements &amp; Certificates
            </Headline>
            <ul className="sm:mt-0 mt-3 divide-y divide-gray-200">
              {achievements.map((achievement, index) => (
                <Achievement key={index} achievement={achievement} />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Credibility;

const Publication = ({ publication }) => {
  let formattedDate = formatGCMSDate(publication.date);

  return (
    <div
      className="h-full flex flex-col justify-between"
      key={publication.title}
    >
      <div>
        <div className="flex gap-x-1 -ml-1"></div>
        <a
          href={publication.url}
          target="_blank"
          rel="noreferrer"
          className="block mt-1"
        >
          <div className="flex gap-x-1 items-center">
            <p className="text-base text-gray-500 leading-8 mr-2">
              {formattedDate}
            </p>
            <div>
              {publication.tags.map((tag) => (
                <span
                  key={tag.name}
                  className="uppercase inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium"
                  style={{
                    color: tag.textColor.hex,
                    backgroundColor: tag.backgroundColor.hex,
                  }}
                >
                  {tag.name}
                </span>
              ))}
            </div>
          </div>
          <p className="text-xl font-semibold text-gray-900">
            {publication.title}{' '}
            <span className="text-timothy-blue">&rarr;</span>
          </p>
        </a>
      </div>
    </div>
  );
};

const Achievement = ({ achievement }) => {
  let formattedDate = formatGCMSDateShort(achievement.date);

  const getTypeIcon = (type) => {
    switch (type) {
      case 'award':
        return <SparklesIcon className="h-4 w-4" aria-hidden="true" />;
      case 'launch':
        return <FireIcon className="h-4 w-4" aria-hidden="true" />;
      case 'certificate':
        return <AcademicCapIcon className="h-4 w-4" aria-hidden="true" />;
      default:
        return <BadgeCheckIcon className="h-4 w-4" aria-hidden="true" />;
    }
  };

  return (
    <li className="py-5">
      <div className="relative flex flex-wrap justify-center lg:justify-start items-center gap-x-2">
        <div className="min-w-full lg:min-w-0 flex justify-center">
          <div className="flex flex-none w-8 h-8 items-center justify-center rounded-md bg-timothy-blue text-white">
            {getTypeIcon(achievement.achievementType)}
          </div>
        </div>
        <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
          <h3 className="text-base font-medium text-gray-900">
            {formattedDate}
          </h3>
          <svg
            className="hidden md:block mx-1 h-5 w-5 text-timothy-blue"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M11 0h3L9 20H6l5-20z" />
          </svg>
          <h3 className="text-base font-medium text-gray-500">
            {achievement.text}
          </h3>
        </div>
      </div>
    </li>
  );
};
